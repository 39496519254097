// utils/logger.ts

const colors = {
	reset: '\x1b[0m',
	red: '\x1b[31m',
	green: '\x1b[32m',
	yellow: '\x1b[33m',
	blue: '\x1b[34m',
	cyan: '\x1b[36m',
} as const

type Color = keyof typeof colors

class Logger {
	private formatArgs<T extends unknown[]>(args: T): string[] {
		return args.map((arg) => {
			if (arg instanceof Error) {
				return `${arg.name}: ${arg.message}\n${arg.stack}`
			}
			return typeof arg === 'object' && arg !== null ? JSON.stringify(arg, null, 2) : String(arg)
		})
	}

	private log<T extends unknown[]>(
		color: Color,
		method: 'info' | 'warn' | 'error' | 'debug',
		...args: T
	): void {
		const formattedArgs = this.formatArgs(args)
		console[method](`${colors[color]}%s${colors.reset}`, ...formattedArgs)
	}

	info<T extends unknown[]>(...args: T): void {
		this.log('blue', 'info', ...args)
	}

	warn<T extends unknown[]>(...args: T): void {
		this.log('yellow', 'warn', ...args)
	}

	error<T extends unknown[]>(...args: T): void {
		this.log('red', 'error', ...args)
	}

	debug<T extends unknown[]>(...args: T): void {
		this.log('cyan', 'debug', ...args)
	}
}

export const logger = new Logger()
