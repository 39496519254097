import { logger } from '@/utils/logger';
import type { TypedSupabaseClient } from '@/lib/supabase.types'

export const fetchTeamById = async (client: TypedSupabaseClient, teamId: string) => {
	const { data: team, error } = await client.from('teams').select().eq('id', teamId).single()
	if (error) {
		logger.error('Error fetching team:', error)
		throw error
	}

	return team
}
